import { ziezo_tile } from "./ImageImport";

export const stichtingApps = [
  {
    name: "ZieZo",
    url: "https://ziezo.zuidzorg.nl",
    image: ziezo_tile,
    condition: (userOu, all_stichting_access) =>
      userOu.includes(all_stichting_access),
  },
];
