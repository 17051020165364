import React from "react";
import "./home.css";

const Contact = () => {
  return (
    <>
      <hr className="border"></hr>
      <div className="content">
        <h2 className="pagename">Hulp nodig?</h2>
        <div className="sub-content">
          <div className="text-container">
            {/* <p>
              <b>Belangrijke mededeling:</b> We zijn op de hoogte gebracht van
              een storing die ervoor zorgt dat sommige collega’s moeite hebben
              om het WST te bereiken. Het lijkt alleen voor te komen bij de
              collega’s met een Nokia telefoon en lijkt te liggen aan het bellen
              via 4G.
              <br></br>
              <br></br>
              <b>Wat kun je doen?</b>
              <ul>
                <li>
                  <b>Schakel 'Bellen via 4G' uit:</b> Ga naar Instellingen {">"}{" "}
                  Netwerk en Internet {">"} Simkaarten {">"} geoptimaliseerde 4G
                  LTE-modus en zet deze functie uit met het schuifje.
                </li>
                <li>
                  <b>Gebruik een andere telefoon:</b> Probeer het algemene
                  nummer (040-2308400 of 040-2308408) te bereiken met een ander
                  toestel (aangezien het probleem zich alleen voor lijkt te doen
                  bij een Nokia X10)
                </li>
              </ul>
              <b>Wat doen we?</b> We hebben KPN op de hoogte gebracht van de
              problemen en wachten op hun reactie. Hopelijk kunnen ze de oorzaak
              snel vinden en oplossen.
              <br></br>
              <b>Let op:</b> Dit probleem doet zich <b>niet</b> voor bij
              Samsung-telefoons.
            </p> */}
            <p>
              Contactgegevens:<br></br>
              E-mailadres: wst@zuidzorg.nl<br></br>
              Telefoonnummer: 040 - 230 8400 (ma t/m vrij tussen 8:00 en 13:00
              uur bereikbaar)
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default Contact;
