import React, { useState, useEffect, useMemo } from "react";
import { Link } from "react-router-dom";
import { useAuth } from "./AuthContext";
import "./menu.css";
import zuidzorglogo from "../images/zuidzorg.logo.png";
import Clock from "./clock";
import locationTeams from "./../components/device_wissel/locationTeams";

const Menu = () => {
  const { isAuthenticated, userData, logout } = useAuth();
  const [greeting, setGreeting] = useState("");
  const [department, setDepartment] = useState("");
  const [error, setError] = useState("");

  const name = isAuthenticated ? userData.given_name : "";
  const isDelegatedAdmin = isAuthenticated ? userData.isDelegatedAdmin : false;
  const isAdmin = isAuthenticated ? userData.isAdmin : false;
  const primaryEmail = isAuthenticated ? userData.primaryEmail : "";

  const inschrijftool_testen = JSON.parse(process.env.REACT_APP_TOOLTESTERS);

  const handleLogout = () => {
    logout();
  };

  useEffect(() => {
    if (isAuthenticated) {
      setDepartment(userData.organizations?.[0]?.department || "");
    }
  }, [isAuthenticated, userData]);

  useEffect(() => {
    const updateGreeting = () => {
      const hour = new Date().getHours();
      if (hour < 12) {
        setGreeting("Goedemorgen");
      } else if (hour < 18) {
        setGreeting("Goedemiddag");
      } else {
        setGreeting("Goedenavond");
      }
    };

    updateGreeting();
    const intervalId = setInterval(updateGreeting, 60000);

    return () => clearInterval(intervalId);
  }, []);

  const canRegister = useMemo(() => {
    return Object.values(locationTeams).some((teams) =>
      teams.includes(department)
    );
  }, [department]);

  return (
    <div className="menu">
      <div className="logo-container">
        <span>
          <img className="zzlogo" src={zuidzorglogo} alt="Logo" />
        </span>
        <span className="name">
          <h2>
            {greeting}&nbsp;{name}
          </h2>
        </span>
      </div>
      <ul>
        <li>
          <Link to="/">Apps</Link>
        </li>
        <li>
          <Link to="/account">Account</Link>
        </li>
        <li>
          <Link to="/help">Help</Link>
        </li>
        {isAuthenticated && department.includes("MNT") && (
          <li>
            <Link to="/MNT">MNT</Link>
          </li>
        )}
        <li>
          {isAuthenticated ? (
            <Link onClick={handleLogout}>Uitloggen</Link>
          ) : (
            <Link to="/">Inloggen</Link>
          )}
        </li>
        {(isAdmin || isDelegatedAdmin) && (
          <li>
            <Link to="/tools">Tools</Link>
          </li>
        )}
        <li>
          {isAuthenticated &&
            (canRegister || inschrijftool_testen.includes(primaryEmail)) && (
              <Link to="/inschrijven">Device Wissel</Link>
            )}
        </li>
        <li className="floatright">
          <Clock />
        </li>
      </ul>
      {error && <div className="error-message">{error}</div>}
    </div>
  );
};

export default Menu;
